import {
  AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import { ProgramService } from '../program.service';
import { Program } from '../program';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare var Blockly;

@Component({
  selector: 'app-blockly',
  templateUrl: './blockly.component.html',
  styleUrls: ['./blockly.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BlocklyComponent implements AfterViewInit, OnDestroy {

  blocklyWorkspace: any;
  @ViewChild('blocklyDiv') blocklyDiv;
  @ViewChild('blocklyDivWrapper') blocklyDivWrapper: ElementRef;
  @ViewChild('startBlocks') blocklyStartBlocks: ElementRef;

  @Output() onNewProject: EventEmitter<boolean> = new EventEmitter<boolean>();

  private programService: ProgramService;

  public blocklyConfigXml: SafeHtml;

  private removeMenuHelpOption = true;

  constructor(programService: ProgramService, sanitizer: DomSanitizer) {
    this.addAdditionalBlocklyMethods();
    this.programService = programService;
    this.blocklyConfigXml = sanitizer.bypassSecurityTrustHtml(this.getBlocklyConfigXml());
  }

  ngAfterViewInit(): any {
    if (this.blocklyDiv) {
      this.injectBlocklyWorkspace();
    }
  }

  ngOnDestroy(): any {
    if (Blockly.getMainWorkspace() != null) {
      Blockly.getMainWorkspace().dispose();
    }
  }

  loadCurrentProgram() {
    const currentProgram: Program = this.programService.getCurrentProgram();
    Blockly.getMainWorkspace().clear();
    if (currentProgram != null && currentProgram.hasCode()) {
      const xml = Blockly.Xml.textToDom(currentProgram.blocklyXml);
      Blockly.Xml.domToWorkspace(xml, Blockly.getMainWorkspace());
    } else {
      const startingBlocksXml = document.getElementById('startBlocks');
      Blockly.Xml.domToWorkspace(startingBlocksXml, Blockly.getMainWorkspace());
    }
    this.centerWorkspace();
  }

  clearWorkspace() {
    const workspace = Blockly.getMainWorkspace();
    workspace.clear();
    const startingBlocksXml = document.getElementById('startBlocks');
    Blockly.Xml.domToWorkspace(startingBlocksXml, workspace);
    this.centerWorkspace();
  }

  centerWorkspace() {
    const workspace = Blockly.getMainWorkspace();

    workspace.markFocused();
    workspace.setScale(workspace.options.zoomOptions.startScale);
    workspace.scrollCenter();
    // Blockly.getMainWorkspace().zoomReset();
  }



  private injectBlocklyWorkspace() {
    if (Blockly.getMainWorkspace() != null) {
      Blockly.getMainWorkspace().dispose();
    }

    const _this = this;
    this.blocklyWorkspace = Blockly.inject('blocklyDiv',
      {
        toolbox: document.getElementById('toolbox'),
        comments: false,
        trashcan: true,
        media: 'blockly/media/',
        sounds: false,
        zoom: {
          controls: true,
          wheel: true,
          startScale: 1.0,
          maxScale: 3,
          minScale: 0.3,
          scaleSpeed: 1.2
        },
      }
    );
    this.blocklyWorkspace.addChangeListener(function (event) {
      _this.onCodeUpdate(event);
    });
    this.updateBlocklyDivSize();


    if (this.removeMenuHelpOption) {
      Blockly.ContextMenu.blockHelpOption = function () { return null; }
    }

    // prompt bug fix by overrding core function
    /*  Blockly.FieldDropdown.prototype.showEditor_ = function() {
        Blockly.WidgetDiv.show(this, this.sourceBlock_.RTL, null);
       // var menu = this.menuGenerator_;
        // this.addEventListeners_(menu);
        //this.addActionListener_(menu);
        //this.positionMenu_(menu);
      }; */

    this.registerSpecialToolbox();

  }

  private onCodeUpdate(event) {
    const workspace = Blockly.getMainWorkspace();
    const arduinoCode = Blockly.Arduino.workspaceToCode(workspace);
    const xml = Blockly.Xml.workspaceToDom(workspace);
    const blocklyXml = Blockly.Xml.domToText(xml);
    this.programService.updateProgramSource(arduinoCode, blocklyXml);
    this.checkSpecialToolBoxBlocks(event);
  }

  @HostListener('window:resize')
  private updateBlocklyDivSize() {
    if (this.blocklyDiv) {
      setTimeout(() => {
        this.blocklyDiv.nativeElement.style.width = this.blocklyDivWrapper.nativeElement.offsetWidth;
        this.blocklyDiv.nativeElement.style.height = this.blocklyDivWrapper.nativeElement.offsetHeight - 43;
        Blockly.svgResize(this.blocklyWorkspace);
      }, 100);
    }
  }

  registerSpecialToolbox() {
    const registerCallBackMenuList = ['SPECIAL', 'PIXEL'];

    registerCallBackMenuList.forEach((menuName) => {
      this.blocklyWorkspace.registerToolboxCategoryCallback(menuName, this.getXmlListMenu.bind(this, menuName));
    });
  }

  getXmlListMenu(menuName) {

    let blockTextList = this.checkBlockExists('set_neo_pixel')
      ? this.getBlockList(true, menuName)
      : this.getBlockList(false, menuName);
    let xmlList = [];
    for (let i = 0; i < blockTextList.length; i++) {
      let block = Blockly.Xml.textToDom(blockTextList[i]);
      xmlList.push(block);
    }
    return xmlList;
  }

  checkBlockExists(blockType) {
    let blocks = this.blocklyWorkspace.getAllBlocks();

    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].type === blockType) {
        return true;
      }
    }

    return false;
  }

  checkSpecialToolBoxBlocks(event) {
    if (event.type == Blockly.Events.BLOCK_DELETE
      && !this.checkBlockExists('set_neo_pixel')
      && (this.checkBlockExists('fast_led')
        || this.checkBlockExists('set_pixel_color')
        || this.checkBlockExists('fast_led_clear')
        || this.checkBlockExists('set_dimming_pixels')
        || this.checkBlockExists('expert_kopiere_pixel')
      )
    ) {
      this.blocklyWorkspace.removeBlockByType('set_pixel_color');
      this.blocklyWorkspace.removeBlockByType('fast_led');
      this.blocklyWorkspace.removeBlockByType('fast_led_clear');
      this.blocklyWorkspace.removeBlockByType('set_dimming_pixels');
      this.blocklyWorkspace.removeBlockByType('expert_kopiere_pixel');
    }
    if (event.type == Blockly.Events.BLOCK_CREATE
      && this.checkBlockExists('set_neo_pixel')
      && this.checkBlockExists('expert_timely_interrupt')
    ) {
      this.blocklyWorkspace.removeBlockByType('expert_timely_interrupt');
    }
  }

  addAdditionalBlocklyMethods() {
    Blockly.Workspace.prototype.removeBlockByType = function (blockType) {
      var blocks = this.getAllBlocks();

      for (var i = 0; i < blocks.length; i++) {
        if (blocks[i].type === blockType) {
          blocks[i].dispose();
        }
      }
    };
  }

  private getBlocklyConfigXml() {
    return '<xml id="toolbox" style="display: none">\n' +
      '  <section title="Aktion"></section>\n' +
      '  <category name="Sensor" id="Sensor" colour="#6BD425">\n' +
      '    <block type="sensor_test_state" level="basic">\n' +
      '      <value name="SENSOR">\n' +
      '        <block type="sensor_pin">\n' +
      '          <field name="SENSOR">SENSOR1</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="STATE">\n' +
      '        <block type="basic_digital_state">HIGH</block>\n' +
      '      </value>\n' +
      '    </block>\n' +
      '    <block type="sensor_read_percentage" level="basic">\n' +
      '      <value name="SENSOR">\n' +
      '        <block type="sensor_pin">\n' +
      '          <field name="SENSOR">SENSOR1</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>\n' +
      '    <block type="sensor_read_state" level="extended">\n' +
      '      <value name="SENSOR">\n' +
      '        <block type="sensor_pin">\n' +
      '          <field name="SENSOR">SENSOR1</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>\n' +
      '    <block type="sensor_pin" level="extended"></block>\n' +
      '  </category>\n' +
      '  <category name="Motor" colour="#6BD425">\n' +
      '    <block type="motor_write_percentage">\n' +
      '      <value name="MOTOR">\n' +
      '        <block type="motor_pin">\n' +
      '          <field name="MOTOR">MOTOR1</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="PERCENTAGE">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">100</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>\n' +
      '    <block type="motor_pin" level="extended"></block>\n' +
      '  </category>\n' +
      '  <category name="LED" colour="#6BD425">\n' +
      '    <block type="led_write_state" level="basic">\n' +
      '      <value name="LED">\n' +
      '        <block type="led_pin">\n' +
      '          <field name="LED">LED1</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="VALUE">\n' +
      '        <block type="basic_digital_state">HIGH</block>\n' +
      '      </value>\n' +
      '    </block>\n' +

      '    <block type="led_write_percentage" level="basic">\n' +
      '      <value name="LED">\n' +
      '        <block type="led_pin">\n' +
      '          <field name="LED">LED1</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="VALUE">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">100</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>\n' +

      '    <block type="led_read_state" level="extended">\n' +
      '      <value name="LED">\n' +
      '        <block type="led_pin">\n' +
      '          <field name="LED">LED1</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>\n' +
      '    <block type="led_pin" level="extended"></block>\n' +
      '  </category>\n' +
      '  <category name="Pixel" custom="PIXEL">\n' +
      '    <block type="set_neo_pixel" level="extended">\n' +
      '      <value name="NUM_LEDS">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">72</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="BRIGHTNESS">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">50</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '     <value name="OUTTURN">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">12</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>' +
      '  </category>\n' +
      '  <section title="Logik"></section>\n' +
      '  <category name="Frage" colour="#6BD425">\n' +
      '    <block type="logic_if"></block>\n' +
      '    <block type="logic_if"><mutation else="1"></mutation></block>\n' +
      '    <block type="logic_compare"></block>\n' +
      '    <block type="logic_operation" level="extended"></block>\n' +
      '    <block type="logic_negate" level="extended"></block>\n' +
      '    <block type="basic_digital_state" level="extended"></block>\n' +
      '  </category>\n' +
      /* '  <category name="Ablauf" colour="#6BD425">\n' +
       '    <block type="flow_delay">\n' +
       '      <value name="DELAY">\n' +
       '        <block type="math_number">\n' +
       '          <field name="NUM">1000</field>\n' +
       '        </block>\n' +
       '      </value>\n' +
       '    </block>\n' +
       '    <block type="flow_repeat" level="extended">\n' +
       '      <value name="REPEATS">\n' +
       '        <block type="math_number">\n' +
       '          <field name="NUM">10</field>\n' +
       '        </block>\n' +
       '      </value>\n' +
       '    </block>\n' +
       '    <block type="flow_while" level="extended"></block>\n' +
       '    <block type="flow_until" level="extended"></block>\n' +
       '    <block type="flow_for" level="extended">\n' +
       '      <value name="FROM">\n' +
       '        <block type="math_number">\n' +
       '          <field name="NUM">0</field>\n' +
       '        </block>\n' +
       '      </value>\n' +
       '      <value name="TO">\n' +
       '        <block type="math_number">\n' +
       '          <field name="NUM">10</field>\n' +
       '        </block>\n' +
       '      </value>\n' +
       '      <value name="BY">\n' +
       '        <block type="math_number">\n' +
       '          <field name="NUM">1</field>\n' +
       '        </block>\n' +
       '      </value>\n' +
       '    </block>\n' +
       '  </category>\n' + */
      '  <category name="Ablauf" colour="#6BD425">\n' +
      ' <block type="flow_delay">\n' +
      ' <value name="DELAY">\n' +
      '  <block type="math_number">\n' +
      '    <field name="NUM">1000</field>\n' +
      '   </block>\n' +
      ' </value>\n' +
      '</block>\n' +
      ' <block type="flow_delay_micro">\n' +
      '  <value name="DELAY_MICRO">\n' +
      '   <block type="math_number">\n' +
      '     <field name="NUM">1000</field>\n' +
      '   </block>\n' +
      ' </value>\n' +
      ' </block>\n' +
      '<block type="flow_repeat" level="extended">\n' +
      '  <value name="REPEATS">\n' +
      '   <block type="math_number">\n' +
      '     <field name="NUM">10</field>\n' +
      '    </block>\n' +
      '   </value>\n' +
      ' </block>\n' +
      ' <block type="flow_while" level="extended"></block>\n' +
      ' <block type="flow_until" level="extended"></block>\n' +
      ' <block type="flow_for" level="extended">\n' +
      '  <value name="FROM">\n' +
      '    <block type="math_number">\n' +
      '     <field name="NUM">0</field>\n' +
      '   </block>\n' +
      ' </value>\n' +
      '<value name="TO">\n' +
      '  <block type="math_number">\n' +
      '    <field name="NUM">10</field>\n' +
      '  </block>\n' +
      ' </value>\n' +
      '  <value name="BY">\n' +
      '    <block type="math_number">\n' +
      '     <field name="NUM">1</field>\n' +
      '   </block>\n' +
      '  </value>\n' +
      ' </block>\n' +
      ' <block type="flow_ms" level="extended"></block>\n' +
      ' <block type="flow_micro" level="extended"></block>\n' +
      ' </category>\n' +
      '  <section title="Zahl"></section>\n' +
      '  <category name="Variable" custom="VARIABLE" colour="#6BD425"></category>\n' +
      '  <category name="Mathe" colour="#6BD425">\n' +
      '    <block type="math_number"></block>\n' +
      '    <block type="math_arithmetic"></block>\n' +
      '    <block type="math_map" level="extended">\n' +
      '      <value name="VALUE"></value>\n' +
      '      <value name="FROMLOW">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">0</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="FROMHIGH">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">0</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="TOLOW">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">0</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="TOHIGH">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">0</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>\n' +
      '    <block type="math_constrain" level="extended">\n' +
      '      <value name="VALUE">\n' +
      '      </value>\n' +
      '      <value name="MIN">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">0</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="MAX">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">100</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>\n' +
      '    <block type="math_round_precision" level="extended"></block>\n' +
      '    <block type="math_random_int" level="extended">\n' +
      '      <value name="FROM">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">0</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="TO">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">4</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>\n' +
      '    <block type="math_div" level="extended"></block>\n' +
      '    <block type="math_minmax" level="extended"></block>\n' +
      '    <block type="math_single_basic" level="extended"></block>\n' +
      '    <block type="math_trig" level="extended"></block>\n' +
      '    <block type="math_constant" level="extended"></block>\n' +
      '    <block type="math_single_extended" level="extended"></block>\n' +
      '  </category>\n' +
      '  <section title="Struktur"></section>\n' +
      '  <category name="Funktion" custom="PROCEDURE" colour="#6BD425"></category>\n' +
      '  <section title="Loop"></section>\n' +
      '  <category name="Spezial" custom="SPECIAL"  colour="#6BD425">\n' +
      '  </category>\n' +
      '</xml>\n' +
      '<xml id="startBlocks" style="display: none">\n' +
      '  <block type="basic_main_loop" deletable="false">\n' +
      '    <value name="BLINK">\n' +
      '      <block type="basic_blinkcode"></block>\n' +
      '    </value>\n' +
      '  </block>\n' +
      '</xml>';
  }

  private getBlockList(libAdded, menuName) {

    let blockList;
    switch (menuName) {
      case 'SPECIAL':
        blockList = this.getCustomSpecialFlyoutBlockList(libAdded)
        break;
      case 'PIXEL':
        blockList = this.getCustomPixelFlyoutBlockList(libAdded)
        break;
      default:
        blockList = [];
    }

    return blockList;

  }

  private getCustomPixelFlyoutBlockList(libAdded) {


    const blocksText1 = [
      '    <block type="set_neo_pixel">\n' +
      '      <value name="NUM_LEDS">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">72</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '      <value name="BRIGHTNESS">\n' +
      '        <block type="math_number">\n' +
      '          <field name="NUM">50</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '     <value name="OUTTURN">\n' +
      '        <block type="led_pin">\n' +
      '          <field name="LED">LED1</field>\n' +
      '        </block>\n' +
      '      </value>\n' +
      '    </block>'
    ];
    if (libAdded) {
      blocksText1.splice(0, 1);
      blocksText1.push(
        '    <block type="set_pixel_color">\n' +
        '      <value name="FROM">\n' +
        '        <block type="math_number">\n' +
        '          <field name="NUM">1</field>\n' +
        '        </block>\n' +
        '      </value>\n' +
        '      <value name="TO">\n' +
        '        <block type="math_number">\n' +
        '          <field name="NUM">1</field>\n' +
        '        </block>\n' +
        '      </value>\n' +
        '      <value name="H">\n' +
        '        <block type="math_number">\n' +
        '          <field name="NUM">120</field>\n' +
        '        </block>\n' +
        '      </value>\n' +
        '      <value name="S">\n' +
        '        <block type="math_number">\n' +
        '          <field name="NUM">100</field>\n' +
        '        </block>\n' +
        '      </value>\n' +
        '      <value name="B">\n' +
        '        <block type="math_number">\n' +
        '          <field name="NUM">100</field>\n' +
        '        </block>\n' +
        '      </value>\n' +
        '    </block>\n',

        '    <block type="expert_kopiere_pixel" level="extended">\n' +
        '      <value name="PIXEL">\n' +
        '        <block type="math_number">\n' +
        '          <field name="NUM">1</field>\n' +
        '        </block>\n' +
        '      </value>\n' +
        '      <value name="NACHPIXEL">\n' +
        '        <block type="math_number">\n' +
        '          <field name="NUM">11</field>\n' +
        '        </block>\n' +
        '      </value>\n' +
        '    </block>',

        '    <block type="fast_led" level="extended"></block>\n',

        '    <block type="fast_led_clear" level="extended"></block>\n',

        '    <block type="set_dimming_pixels" level="extended">\n' +
        '      <value name="PIXEL">\n' +
        '        <block type="math_number">\n' +
        '          <field name="NUM">1</field>\n' +
        '        </block>\n' +
        '      </value>\n' +
        '      <value name="PERCENT">\n' +
        '        <block type="math_number">\n' +
        '          <field name="NUM">1</field>\n' +
        '        </block>\n' +
        '      </value>\n' +
        '    </block>'
      );
    }
    return blocksText1;
  }

  private getCustomSpecialFlyoutBlockList(libAdded) {
    const blocksText = [
      '    <label text="Dokumentation " web-class="customLabelStyle"></label>',
      '    <block type="basic_comment"></block>',
      '    <block type="basic_blinkcode"></block>',
      '    <label text="Loop" web-class="customLabelStyle"></label>',
      '    <block type="expert_setup"></block>',
      '    <block type="expert_timely_interrupt"></block>',
      '    <block type="code_declaration"></block>',
      '    <block type="code_input"></block>',
      '    <block type="code_pin"></block>'
    ];

    if (libAdded) {
      blocksText.splice(5, 1);
    }

    return blocksText;
  }

}
