import { Component, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { BlocklyComponent } from './blockly/blockly.component';
import { ProgramAudioComponent } from './program-audio/program-audio.component';
import { ProgramService } from './program.service';
import { ProgramLocal } from './program-local';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

  @ViewChild(BlocklyComponent) blocklyComponent: BlocklyComponent;
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('navigation') navigation: ElementRef;
  @ViewChild('contentWindow') contentWindow: ElementRef;
  @ViewChild(ProgramAudioComponent) audioComponent: ProgramAudioComponent;
  @ViewChild('programStartupDialog') programStartupDialog: ElementRef;

  contentWidth: string;
  contentHeight: string;
  askStartupDialog: boolean;
  isSyncing = false;
  isTeacherBoxPluggedIn = false;
  private currentProgramName: string;
  private defaultState = 'edit'; // edit, code, simulator, connect, help
  state: string = this.defaultState;
  private programService: ProgramService;
  private document: Document;
  public externalLink = "https://pglu.ch/produkt-kategorie/shop-fuer-arduino-und-neopixel-in-der-schule/";

  constructor(el: ElementRef, programService: ProgramService) {
    this.document = el.nativeElement.ownerDocument;
    this.programService = programService;
    this.currentProgramName = programService.getCurrentProgram().name;
    this.askStartupDialog = this.programService.getCurrentProgram().id !== null;
  }

  ngAfterViewInit() {
    const startupScreen = this.document.getElementById('logoStartupScreen');
    startupScreen.addEventListener('transitionend', () => {
      startupScreen.parentNode.removeChild(startupScreen);
      this.blocklyComponent.loadCurrentProgram();
    }, true);
    this.onWindowResize();
    setTimeout(() => { startupScreen.style.opacity = '0'; this.blocklyComponent.loadCurrentProgram(); }, 1000);
  }

  onStateToggle(newState: string) {
    if (this.state === newState && newState === 'connect') {
      this.isTeacherBoxPluggedIn = false;
    }
    this.state = this.state !== newState ? newState : this.defaultState;
    if (newState !== this.defaultState) {
      this.blocklyComponent.centerWorkspace();
    }

  }

  onSyncClick() {
    if (this.programService.currentProgramIsOnTeachersBox()) {
      this.isSyncing = true;
      this.programService.syncCurrentProgram();
      if (this.programService.getCurrentProgram().armed) {
        this.audioComponent.playUploadAndArmSounds();
      } else {
        this.audioComponent.playUploadSound();
      }
      setTimeout(() => { this.isSyncing = false; }, 1000);
    } else {
      this.state = 'connect';
    }
  }

  public onStartupQuestionNewProject() {
    this.onProgramNew();
    this.askStartupDialog = false;
  }

  public onStartupQuestionExistingProject() {
    this.askStartupDialog = false;
  }

  public onProgramNew() {
    this.programService.reset();
    this.blocklyComponent.loadCurrentProgram();
  }

  public onProgramLoad() {
    this.blocklyComponent.loadCurrentProgram();
  }

  public onXmlUpdate(xml: string) {
    this.programService.setCurrentProgram(ProgramLocal.createWithXML(xml));
    this.blocklyComponent.loadCurrentProgram();
  }

  public teachersBoxPlugged($event) {
    this.isTeacherBoxPluggedIn = $event;
  }

  @HostListener('window:resize', ['$event'])
  private onWindowResize() {
    setTimeout(() => {
      const width = this.wrapper.nativeElement.offsetWidth;
      const height = this.wrapper.nativeElement.offsetHeight - this.navigation.nativeElement.offsetHeight;
      this.contentWidth = width.toString();
      this.contentHeight = height.toString();
    }, 100);
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    setTimeout(() => { this.blocklyComponent.centerWorkspace(); }, 1000);
  }
}
