import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BlocklyComponent } from './blockly/blockly.component';
import { ProgramAudioComponent } from './program-audio/program-audio.component';
import { ProgramService } from './program.service';
import { ProgramBackendService } from './program-backend.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EditorModalCodeComponent } from './editor-modal-code/editor-modal-code.component';
import { EditorModalConnectComponent } from './editor-modal-connect/editor-modal-connect.component';
import { EditorModalHelpComponent } from './editor-modal-help/editor-modal-help.component';
import { EditorModalSimulatorComponent } from './editor-modal-simulator/editor-modal-simulator.component';
import { FormsModule } from '@angular/forms';
import { SimulatorInputToggleDirective } from './simulator-input-toggle.directive';
import { CustomHttpInterceptorService } from './http.interceptor';
import { HighlightJsModule } from 'ngx-highlight-js';
import { SettingModalComponent } from './settings-modal/settings-modal.component';
import { SettingsService } from './settings-service';

@NgModule({
  declarations: [
    AppComponent,
    BlocklyComponent,
    ProgramAudioComponent,
    EditorModalCodeComponent,
    EditorModalConnectComponent,
    EditorModalHelpComponent,
    EditorModalSimulatorComponent,
    SimulatorInputToggleDirective,
    SettingModalComponent
  ],
  imports: [BrowserModule, FormsModule, HttpClientModule, HighlightJsModule ],
  providers: [
    ProgramService,
    ProgramBackendService,
    SettingsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptorService,
      multi: true
    }   
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
