<div #outline class="modalOutline" (click)="onOutlineClick()"></div>
<div #editorModalDiv id="editorModalCodeWrapper" class="modalDialog">
  <div class="modalCodeNavigation">
    <div id="modalCodeNavTriangleWrapper"><div class="navTriangle"></div></div>
    <ul class="navSmall">
      <li [class.active]="isArduinoCode" (click)="onArduinoCode()">Arduino Mikrocontroller(C++)</li>
      <li [class.active]="!isArduinoCode" (click)="onXmlCode()">PGLU Editor (XML)</li>   
      <li (click)="onImport()" *ngIf="!isArduinoCode" style="float: right">in Grafik umwandeln</li>
      <li (click)="pasteCode(currentXMLBlock)" *ngIf="!isArduinoCode && isHttps" style="float: right">einfügen</li>
      <li (click)="clearCode(currentXMLBlock)" *ngIf="!isArduinoCode && !isHttps" style="float: right">leeren und von Hand einfügen</li>        
      <li (click)="copyCode(isArduinoCode ? currentCodeBlock : currentXMLBlock)" style="float: right">kopieren</li>
    </ul>
  </div>
  <div class="modalCodeWrapper">
    <div class="modalCodeTextarea" *ngIf="isArduinoCode" style=" white-space: pre;" contenteditable="true" spellcheck="false">      
     <textarea highlight-js [options]="{}" [lang]="'C++'"  [ngModel]="currentCode"  ></textarea>           
    </div> 
    <textarea [ngModel]="currentCode"  #currentCodeBlock class="hideme"  *ngIf="isArduinoCode"></textarea>   
    <textarea class="modalCodeTextarea" [(ngModel)]="currentXML" *ngIf="!isArduinoCode" #currentXMLBlock ></textarea>
  </div>


</div>
