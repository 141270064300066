<div *ngIf="askStartupDialog" #programStartupDialog id="programLoadStartupDialog">
  <ul id="navProgramLoadStartupDialog">
    <li (click)="onStartupQuestionNewProject()">Neues Projekt</li>
    <li (click)="onStartupQuestionExistingProject()">Letztes Projekt bearbeiten</li>
  </ul>
</div>
<!--<div  class="warning-message">Editor im Querformat verwenden</div> -->
<div #wrapper class="wrapper">
  <div #navigation class="navigation">
    <div class="navSection navSectionLogo">
      <img (click)="onSyncClick()" [src]="isSyncing ? 'assets/image/logo-hover.svg' : 'assets/image/logo.svg'"  onmouseover="this.src='assets/image/logo-hover.svg'" onmouseout="this.src='assets/image/logo.svg'"  class="logo"/>
    </div>
    <div class="navSection" style="margin: 6px 10px 0px 0px;">
      <!-- <p class="title">Programmieren</p> -->
      <ul class="nav">
        <li  (click)="onStateToggle('edit')" [class.active]="state=='edit'">Grafik</li>
        <!-- <li (click)="onStateToggle('code')" [class.active]="state=='code'" style="float: right">Code</li> -->
      </ul>
    </div>
    <div class="navSection">
      <ul class="nav">
      <li (click)="onStateToggle('code')" [class.active]="state=='code'" style="float: right">Code</li>
      </ul>
    </div>
    <div class="navSection">
      <!-- <p class="title">Testen</p> -->
      <ul class="nav">
        <li (click)="onStateToggle('simulator')" [class.active]="state=='simulator'">Simulator</li>
      </ul>
    </div>
    <div class="navSection">
      <!-- <p class="title">Organisieren</p> -->
      <ul class="nav">
        <li (click)="onStateToggle('connect')" [class.active]="state=='connect'">Programme</li>
      </ul>
    </div>
   
    <div class="navSection shopping" >   
      <i class="settings mr-10" (click)="onStateToggle('settings')"></i> 
      <a target="_blank" [href]="externalLink" *ngIf="!isTeacherBoxPluggedIn">
         <i class="fas fa-shopping-cart  mr-10"></i>
      </a>      
    </div>
    <!-- <div class="navSection"> -->
      <!-- <p class="title">Hilfe</p> -->
      <!-- <ul class="nav">
        <li (click)="onStateToggle('help')" [class.active]="state=='help'">?</li>
      </ul> -->
    <!-- </div> -->
  </div>
  <div #contentWindow class="content">
    <div class="programEditWrapper">
      <app-blockly (onNewProject)="onStateToggle('connect')" style="width: 100%; height: 100%"></app-blockly>
      <div *ngIf="state==='code'"><app-editor-modal-code (xmlUpdate)="onXmlUpdate($event)" (close)="onStateToggle('edit')" mainContentWidth="{{contentWidth}}" mainContentHeight="{{contentHeight}}"></app-editor-modal-code></div>
      <div *ngIf="state==='connect'"><app-editor-modal-connect (onProgramLoad)="onProgramLoad()" (onProgramNew)="onProgramNew()" (close)="onStateToggle('edit')" (teachersBoxPlugged)="teachersBoxPlugged($event)" mainContentWidth="{{contentWidth}}" mainContentHeight="{{contentHeight}}"></app-editor-modal-connect></div>
      <div *ngIf="state==='simulator'"><app-editor-modal-simulator mainContentWidth="{{contentWidth}}" mainContentHeight="{{contentHeight}}"></app-editor-modal-simulator></div>
      <div *ngIf="state==='help'"><app-editor-modal-help (close)="onStateToggle('edit')" mainContentWidth="{{contentWidth}}" mainContentHeight="{{contentHeight}}"></app-editor-modal-help></div>
      <div *ngIf="state==='settings'"><app-settings-modal mainContentWidth="{{contentWidth}}" mainContentHeight="{{contentHeight}}" (close)="onStateToggle('settings')"></app-settings-modal></div>
    </div>
  </div>
</div>
<app-program-audio></app-program-audio>






