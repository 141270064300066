import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-editor-modal-help',
  templateUrl: './editor-modal-help.component.html',
  styleUrls: ['./editor-modal-help.component.css']
})
export class EditorModalHelpComponent implements AfterViewInit, OnChanges {

  @Input('mainContentWidth') contentWidth: string;
  @Input('mainContentHeight') contentHeight: string;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('editorModalDiv') editorModalDiv: ElementRef;
  @ViewChild('outline') outlineDiv: ElementRef;


  ngAfterViewInit() {
    this.updateModalBoxSize();   
  }

  ngOnChanges(changes: {}): any {
    this.updateModalBoxSize();
  }

  onOutlineClick() {
    this.close.emit(true);
  }

  private updateModalBoxSize() {
    if (this.editorModalDiv) {
      const topNavigationHeight = 50;
      const verticalSpacing = 20;
      const adjustedHeight = parseInt(this.contentHeight) - 2 * verticalSpacing - 20; // for some reason, height is 20 px less on iPad
      this.editorModalDiv.nativeElement.style.top = topNavigationHeight + verticalSpacing;
      this.editorModalDiv.nativeElement.style.height = adjustedHeight;

      const leftNavigationWidth = 80;
      const horizontalSpacing = 45;
      const adjustedWidth = parseInt(this.contentWidth) - leftNavigationWidth - 2 * horizontalSpacing;
      this.editorModalDiv.nativeElement.style.left = leftNavigationWidth + horizontalSpacing;
      this.editorModalDiv.nativeElement.style.width = adjustedWidth;
    }
    if (this.outlineDiv) {
      this.outlineDiv.nativeElement.style.width = this.contentWidth;
      this.outlineDiv.nativeElement.style.height = this.contentHeight;
    }
  }

}
