import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProgramService} from '../program.service';

declare var Blockly;
declare var goog;
declare class Interpreter {
  constructor(code, opt_initFunc);
}

@Component({
  selector: 'app-editor-modal-simulator',
  templateUrl: './editor-modal-simulator.component.html',
  styleUrls: ['./editor-modal-simulator.component.css']
})
export class EditorModalSimulatorComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input('mainContentWidth') contentWidth: string;
  @Input('mainContentHeight') contentHeight: string;
  @ViewChild('editorModalDivInput') editorModalDivInput: ElementRef;
  @ViewChild('editorModalDivOutput') editorModalDivOutput: ElementRef;
  @ViewChild('editorModalDivSpeed') editorModalDivSpeed: ElementRef;

  private programService: ProgramService;
  private interpreterStepTimer;
  private interpreter;
  private simulatorSpeedMin = 10;
  private simulatorSpeedMax = 500;
  simulatorSpeed = 500;

  private get inveresedSimulationSpeed() {
    return (this.simulatorSpeed - this.simulatorSpeedMin) * (this.simulatorSpeedMin - this.simulatorSpeedMax) /
      (this.simulatorSpeedMax - this.simulatorSpeedMin) + this.simulatorSpeedMax;
  }

  private simulatorHighlighted = false;

  private ioMapping = {
    '9': 'led1',
    '10': 'led2',
    '3': 'led3',
    '11': 'led4',
    '5': 'motor1',
    '6': 'motor2',
    '14': 'sensor1',
    '15': 'sensor2',
    '16': 'sensor3',
    '17': 'sensor4'
  };


  ledState = {
    'led1': 0,
    'led2': 0,
    'led3': 0,
    'led4': 0
  };

  motorState = {
    'motor1': 0,
    'motor2': 0
  };

  sensorState = {
    'sensor1': 0,
    'sensor2': 0,
    'sensor3': 0,
    'sensor4': 0
  };


  constructor(programService: ProgramService) {
    this.programService = programService;
    programService.subscribeOnArduinoCodeUpdate().subscribe((newCode: string) => {this.onCodeChange(newCode); });
  }

  ngAfterViewInit() {
    Blockly.JavaScript.STATEMENT_PREFIX = 'check_expert_timely_interrupt(); highlightBlock(%1);\n';
    Blockly.JavaScript.addReservedWords('highlightBlock');
    Blockly.JavaScript.addReservedWords('check_expert_timely_interrupt');
    Blockly.JavaScript.addReservedWords('expert_timely_interrupt');
    Blockly.JavaScript.addReservedWords('expert_timely_total_blocks');
    this.initInterpreterWithCurrentCode();
    this.interpreterNextStep();
    this.updateModalBoxSize();
  }

  ngOnChanges(changes: {}): any {
    this.updateModalBoxSize();
  }

  ngOnDestroy() {
    if (this.interpreterStepTimer) {
      clearTimeout(this.interpreterStepTimer);
    }
    Blockly.getMainWorkspace().highlightBlock(null);
  }

  private onCodeChange(newCode: string) {
    this.initInterpreterWithCurrentCode();
  }

  private initInterpreterWithCurrentCode() {
    const jsCode = Blockly.JavaScript.workspaceToCode(Blockly.getMainWorkspace());
    this.interpreter = new Interpreter(jsCode, this.initInterpreterApi.bind(this));
  }

  private initInterpreterApi(interpreter, scope) {
    interpreter.setProperty(scope, 'highlightBlock',
      interpreter.createNativeFunction((id: string) => {
        Blockly.getMainWorkspace().highlightBlock(id);
        this.simulatorHighlighted = true;
      }));

    interpreter.setProperty(scope, 'getSensorStateDigital',
      interpreter.createNativeFunction((sensor: string) => {
        return interpreter.createPrimitive(this.getSensorStateDigital(this.ioMapping[sensor]));
      }));

    interpreter.setProperty(scope, 'getSensorStatePercentage',
      interpreter.createNativeFunction((sensor: string) => {
        //noinspection TypeScriptUnresolvedFunction
        return interpreter.createPrimitive(this.getSensorState(this.ioMapping[sensor]));
      }));

    interpreter.setProperty(scope, 'setLedState',
      interpreter.createNativeFunction((led: string, state: number) => {
        this.setLedState(this.ioMapping[led], state);
      }));

    interpreter.setProperty(scope, 'getLedStateDigital',
      interpreter.createNativeFunction((led: string) => {
        return interpreter.createPrimitive(this.getLedStateDigital(this.ioMapping[led]));
      }));

    interpreter.setProperty(scope, 'setMotorState',
      interpreter.createNativeFunction((motor: string, state: number) => {
         this.setMotorState(this.ioMapping[motor], state);
      }));

    interpreter.setProperty(scope, 'printLog',
      interpreter.createNativeFunction((variable: string) => {
        return interpreter.createPrimitive(console.log(variable));
      }));

    interpreter.setProperty(scope, 'alert',
      interpreter.createNativeFunction((text: string) => {
        text = text ? text.toString() : '';
        return interpreter.createPrimitive(alert(text));
      }));

    interpreter.setProperty(scope, 'getCurrentSimulatorSpeed',
      interpreter.createNativeFunction(() => {
        return interpreter.createPrimitive(this.getCurrentSimulatorSpeed());
      }));


  }

  setSensorState(sensor: string, state: number) {
    if (this.sensorState[sensor] !== undefined) {
      this.sensorState[sensor] = this.positivePercentageRangeMap(state);
    }
  }

  getSensorStateDigital(sensor: string): boolean {
    return this.getSensorState(sensor) >= 50;
  }

  getSensorState(sensor: string): number {
    let value = 0;
    if (this.sensorState[sensor] !== undefined) {
      value = this.sensorState[sensor];
    }
    return value;
  }

  setLedState(led: string, state: number) {
    if (this.ledState[led] !== undefined) {
      this.ledState[led] = this.positivePercentageRangeMap(state);
    }
  }

  getLedState(led: string): number {
    let value = 0;
    if (this.ledState[led] !== undefined) {
      value = this.ledState[led];
    }
    return value;
  }

  getLedStateDigital(led: string): boolean {
    return this.getLedState(led) >= 50;
  }

  setMotorState(motor: string, state: number) {
    if (this.motorState[motor] !== undefined) {
      this.motorState[motor] = this.positiveAndNegativePercentageRangeMap(state);
    }
  }

  getMotorState(motor: string) {
    let value = 0;
    if (this.motorState[motor] !== undefined) {
      value = this.motorState[motor];
    }
    return value;
  }

  private interpreterNextStep() {
    let hasNextStep;
    const timeoutReference = new Date().getTime();
    while (!this.simulatorHighlighted && (hasNextStep = this.interpreter.step()) && new Date().getTime() - timeoutReference < 200) {}
    if (hasNextStep) {
      const nextStepTimeout = this.simulatorHighlighted ? this.inveresedSimulationSpeed : 1;
      this.simulatorHighlighted = false;
      this.interpreterStepTimer = setTimeout(this.interpreterNextStep.bind(this), nextStepTimeout);
    }
  }

  private onSensorDigitalClick(sensor: string, state: boolean) {
    if (state) {
      this.setSensorState(sensor, 100);
    } else {
      this.setSensorState(sensor, 0);
    }
  }

  toggleSensorState(sensor: string) {
    if (this.sensorState[sensor] !== undefined) {
      this.sensorState[sensor] = this.sensorState[sensor] === 100 ? 0 : 100;
    }
  }

  private updateModalBoxSize() {
    const leftNavigationWidth = 80;
    const horizontalSpacing = 45;
    const adjustedWidth = parseInt(this.contentWidth) - leftNavigationWidth - 2 * horizontalSpacing;
    if (this.editorModalDivInput) {
      this.editorModalDivInput.nativeElement.style.left = leftNavigationWidth + horizontalSpacing;
      this.editorModalDivInput.nativeElement.style.width = adjustedWidth;
    }
    if (this.editorModalDivOutput) {
      const topNavigationHeight = 50;
      const verticalSpacing = 20;
      const outputHeight = this.editorModalDivOutput.nativeElement.offsetHeight;
      const computedTop = (topNavigationHeight + parseInt(this.contentHeight)) - verticalSpacing - outputHeight;
      this.editorModalDivOutput.nativeElement.style.top = computedTop;

      this.editorModalDivOutput.nativeElement.style.left = leftNavigationWidth + horizontalSpacing;
      this.editorModalDivOutput.nativeElement.style.width = adjustedWidth;
    }
  }

  public getMotorAsPercentageString(motor: string): string {
    const state = Math.abs(this.getMotorState(motor));
    return ((state * 70 / 100 + 30) / 100).toString();
  }

  getLedStateAsPercentageString(led: string): string {
    const state = this.getLedState(led);
    return ((state * 70 / 100 + 30) / 100).toString();
  }

  private getCurrentSimulatorSpeed() {
    return this.inveresedSimulationSpeed;
  }

  private positivePercentageRangeMap(value) {
    let output = 0;
    const number = parseInt(value);
    if (!isNaN(number) && isFinite(value)) {
      output = number >= 0 && number <= 100 ? number : 0;
    }else if (value === true) {
      output = 100;
    }else if (value === false) {
      output = 0;
    }

    return output;
  }

  private positiveAndNegativePercentageRangeMap(value) {
    let output = 0;
    const number = parseInt(value);
    if (!isNaN(number) && isFinite(value)) {
      output = number >= -100 && number <= 100 ? number : 0;
    }
    return output;
  }

}
