import {EventEmitter, Injectable} from '@angular/core';
import {ProgramBackendService} from './program-backend.service';
import {ProgramLocal, ProgramSyncStatus} from './program-local';
import {Program} from './program';

@Injectable()
export class ProgramService {

  private backend: ProgramBackendService;
  private currentProgram: ProgramLocal;
  private arduinoCodeChangeEmitter: EventEmitter<string>;

  constructor(backend: ProgramBackendService) {
    this.backend = backend;
    this.currentProgram = ProgramLocal.loadFromLocalStorage();
    this.arduinoCodeChangeEmitter = new EventEmitter<string>();
  }

  subscribeOnArduinoCodeUpdate(): EventEmitter<string> {
    return this.arduinoCodeChangeEmitter;
  }

  getPrograms(onSuccess: Function, onError: Function) {
    this.backend.getPrograms().subscribe((programs: Array<Program>) => {
      if (onSuccess) {
        if (this.currentProgram) {
          let currentProgramInResponse = false;
          for (const program of programs) {
            if (this.currentProgram.id === program.id) {
              currentProgramInResponse = true;
              this.currentProgram.updateStatusInformationWithServerResponse(program);
              break;
            }
          }
          if (!currentProgramInResponse) {
            this.currentProgram.markNotListed();
          }
        }
        onSuccess(programs);
      }
    }, () => {
      if (onError) {
        onError();
      }
    });
  }

  reset() {
    this.currentProgram = ProgramLocal.createEmpty();
  }

  syncCurrentProgram(onError?: Function): boolean{
    if (this.currentProgram.name !== null && this.currentProgram.name !== '') {
      if (this.currentProgram.id === null) {
        this.currentProgram.updateCreationDate();
      }
      this.currentProgram.updateModifiedDate();
      this.currentProgram.compiled = false;
      this.currentProgram.hasCompileError = false;
      this.backend.createOrUpdateProgram(this.currentProgram).subscribe((program: Program) => {
        this.currentProgram = ProgramLocal.createWithProgram(program);
        this.currentProgram.syncStatus = ProgramSyncStatus.InSync;
        this.currentProgram.saveToLocalStorage();
      }, () => {
        if (onError) {
          onError();
        }
      });
      return true;
    }
    return false;
  }

  armProgram(program: Program, onError?: Function) {
    this.backend.armProgram(program).subscribe(() => {}, () => {if (onError){onError(); }});
  }

  deleteProgram(program: Program, onError?: Function) {
    return this.backend.deleteProgram(program).subscribe(() => {}, () => {if (onError){onError(); }});
  }

  duplicateProgram(program: Program, switchToNew= false) {
    this.backend.createOrUpdateProgram(program.clone()).subscribe((updatedProgram: Program) => {
      if (switchToNew) {
        this.currentProgram = ProgramLocal.createWithProgram(updatedProgram);
        this.currentProgram.syncStatus = ProgramSyncStatus.InSync;
        this.currentProgram.saveToLocalStorage();
      }
    });
  }

  updateProgramName(name: string) {
    this.currentProgram.updateName(name);
  }

  updateProgramSource(sourceCode: string, blocklyXml: string) {
    if (this.currentProgram.sourceCode !== sourceCode) {
      this.arduinoCodeChangeEmitter.emit(sourceCode);
    }
    this.currentProgram.updateProgramSource(sourceCode, blocklyXml);
    this.currentProgram.saveToLocalStorage();
  }

  getCurrentProgram(): ProgramLocal {
    return this.currentProgram;
  }

  setCurrentProgram(program: Program) {
    this.currentProgram = ProgramLocal.createWithProgram(program);
  }

  currentProgramIsOnTeachersBox() {
    return this.currentProgram.syncStatus !== ProgramSyncStatus.NotListed;
  }

}
