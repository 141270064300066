<div #outline class="modalOutline" (click)="onOutlineClick()"></div>
<div #editorModalDiv id="editorModalConnectWrapper" class="modalDialog">
  <div id="modalSettingsNavTriangleWrapper">
    <div class="navTriangle"></div>
  </div>
  <div class="connectLocalSection">

    <div>
      <div class="setting-input">
        Version Arduino Nano
      </div>
      <div class="setting-input"> <input type="checkbox" [checked]="!oldboard" (change)="callService()" />Ab 2023:
        ATmega 328P (Optiboot) </div>
      <div class="setting-input"> <input type="checkbox" [checked]="oldboard" (change)="callService()" />Bis 2022:
        ATmega 328P (Old
        Bootloader) </div>
    </div>
  </div>



</div>