export class Program {
  constructor(public id: number,
              public name: string,
              public sourceCode: string,
              public blocklyXml: string,
              public createdOn: Date,
              public modifiedOn: Date,
              public compiled: boolean = false,
              public armed: boolean = false,
              public hasCompileError: boolean = false) {
    if (createdOn === null) {
      this.createdOn = new Date();
    }
    if (modifiedOn === null) {
      this.modifiedOn = new Date();
    }
  }

  updateName(name: string) {
    if (name != null) {
      this.name = name;
      this.onProgramModified();
    }
  }

  updateProgramSource(sourceCode: string, blocklyXml: string) {
    if (sourceCode != null) {
      this.sourceCode = sourceCode;
      this.onProgramModified();
    }
    if (blocklyXml != null) {
      this.blocklyXml = blocklyXml;
      this.onProgramModified();
    }
  }


  markNotCompiled() {
    this.compiled = false;
    this.hasCompileError = false;
  }

  hasCode(): Boolean {
    return this.blocklyXml != null;
  }

  clone() {
    return new Program(null, this.name, this.sourceCode, this.blocklyXml, new Date(), new Date());
  }

  updateCreationDate() {
    this.createdOn = new Date();
  }

  updateModifiedDate() {
    this.modifiedOn = new Date();
  }


  private onProgramModified() {
    this.modifiedOn = new Date();
  }
}
