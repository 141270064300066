import {Component, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-program-audio',
  templateUrl: './program-audio.component.html',
  styleUrls: ['./program-audio.component.css']
})
export class ProgramAudioComponent {

  @ViewChild('audioTeachersBoxUpload') audioTeachersBoxUpload: ElementRef;
  @ViewChild('audioTeachersBoxArm') audioTeachersBoxArm: ElementRef;

  playUploadSound() {
    this.audioTeachersBoxUpload.nativeElement.play();
  }

  playArmSound() {
    this.audioTeachersBoxArm.nativeElement.play();
  }

  playUploadAndArmSounds() {
    this.playUploadSound();
    setTimeout(() => {this.playArmSound(); }, 500);
  }

}
