import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output,
  ViewChild
} from '@angular/core';
import { ProgramService } from '../program.service';

@Component({
  selector: 'app-editor-modal-code',
  templateUrl: './editor-modal-code.component.html',
  styleUrls: ['./editor-modal-code.component.css']
})
export class EditorModalCodeComponent implements AfterViewInit, OnChanges {

  @Input('mainContentWidth') contentWidth: string;
  @Input('mainContentHeight') contentHeight: string;
  @ViewChild('editorModalDiv') editorModalDiv: ElementRef;
  @ViewChild('outline') outlineDiv: ElementRef;
  @ViewChild('currentCodeBlock') currentCodeBlock: ElementRef;
  @ViewChild('currentXMLBlock') currentXMLBlock: ElementRef;
  @Output() xmlUpdate: EventEmitter<string> = new EventEmitter<string>();
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  isArduinoCode = true;
  public currentCode: string;
  public currentXML: string;
  public lang = ["C++"];
  public isHttps:boolean = false;

  constructor(programService: ProgramService) {
    this.currentCode = programService.getCurrentProgram().sourceCode;
    this.currentXML = programService.getCurrentProgram().blocklyXml;
    this.checkUrl()
  }

  ngAfterViewInit() {
    this.updateModalBoxSize();
  }

  ngOnChanges(changes: {}): any {
    this.updateModalBoxSize();
  }

  onOutlineClick() {
    this.close.emit(true);
  }

  onArduinoCode() {
    this.isArduinoCode = true;
  }

  onXmlCode() {
    this.isArduinoCode = false;
  }

  onImport() {
    if (!this.isArduinoCode) {
      if (confirm('Achtung: Ihr aktuelles Programm wird unwiderruflich durch das Neue ersetzt. Möchten Sie das wirklich tun?')) {
        this.xmlUpdate.emit(this.currentXML);
        this.close.emit(true);
      }
    }
  }

  copyCode(inputElement) {
    inputElement.nativeElement.select();
    document.execCommand('copy');
    inputElement.nativeElement.setSelectionRange(0, 0);
  }

  pasteCode(inputElement) {
    if (window['clipboardData']) {
      inputElement.nativeElement.value = window['clipboardData'].getData('Text');
    }
    else {
      if(navigator && navigator['clipboard'] && navigator['clipboard'].readText()){
        navigator['clipboard'].readText().then(clipText => {
          inputElement.nativeElement.value  = clipText;
          this.currentXML = clipText;
          // this.xmlUpdate.emit(this.currentXML);
        });
      }
    }
   
  }

  private updateModalBoxSize() {
    if (this.editorModalDiv) {
      const topNavigationHeight = 50;
      const verticalSpacing = 20;
      const adjustedHeight = parseInt(this.contentHeight) - 2 * verticalSpacing - 20; // for some reason, height is 20 px less on iPad
      this.editorModalDiv.nativeElement.style.top = topNavigationHeight + verticalSpacing;
      this.editorModalDiv.nativeElement.style.height = adjustedHeight;

      const leftNavigationWidth = 80;
      const horizontalSpacing = 45;
      const adjustedWidth = parseInt(this.contentWidth) - leftNavigationWidth - 2 * horizontalSpacing;
      this.editorModalDiv.nativeElement.style.left = leftNavigationWidth + horizontalSpacing;
      this.editorModalDiv.nativeElement.style.width = adjustedWidth;
    }
    if (this.outlineDiv) {
      this.outlineDiv.nativeElement.style.width = this.contentWidth;
      // this.outlineDiv.nativeElement.style.height = this.contentHeight;
    }

  }
  private checkUrl():void{
    this.isHttps = window.location.protocol === 'https:';
  }
  public clearCode(inputElement){
    inputElement.nativeElement.value  = '';
    this.currentXML = '';
  }
}
