import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SettingsService {


  private urlBase = "http://192.168.10.1:9000";
  // private static urlBase = "http://152.96.214.138:9000";

  private static jsonHeaderOption = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private _http: HttpClient) {
    this.urlBase = location.origin + ':9000';
  }



  getSettings() {
    return this._http.get(this.urlBase + '/settings/active_board');
  }

  updateSettings(board_id: number): Observable<any> {

    return this._http.put(this.urlBase + '/settings/' + board_id, {});

  }






}
