<div #editorModalDivInput id="editorModalSimulatorInputWrapper" class="modalDialog">
  <div id="modalSimulatorNavTriangleWrapper"><div class="navTriangle"></div></div>
  <div id="simulatorTitleSection">
    <div class="simulatorInputTitleWrapper">
      <p style="padding-top: 4px;">Schalten</p>
      <p>Regulieren</p>
      <p style="padding-bottom: 4px;">Tasten</p>
    </div>
  </div>
  <div id="simulatorInputSection">
    <div class="simulatorInputGroupWrapper">
      <div class="simulatorInputGroup">
        <div class="simulatorInputGroupSection">
          <img class="simulatorInputToggle" src="" [appSimulatorInputToggle]="getSensorStateDigital('sensor1')" (click)="toggleSensorState('sensor1')">
          <img class="simulatorInputButton" src="assets/image/simulator_button.svg" (mousedown)="setSensorState('sensor1',100)" (touchstart)="setSensorState('sensor1',100)" (mouseup)="setSensorState('sensor1',0)" (touchend)="setSensorState('sensor1',0)">
        </div>
        <div class="simulatorInputGroupSection">
          <!--duplicated change event as workaround for ie in angular2 rc.0-->
          <input class="simulatorInputAnalogRange" type="range" name="simulationSpeed" min="0" max="100" step="1" [(ngModel)]="sensorState.sensor1" (change)="setSensorState('sensor1',$event.target.value)">
        </div>
        <div class="simulatorInputGroupSection" style="justify-content: flex-end">
          <input class="simulatorInput" type="text" [(ngModel)]="sensorState.sensor1">
        </div>
      </div>
      <div class="simulatorInputGroupTitle"><p>Sensor 1</p></div>
    </div>

    <div class="simulatorInputGroupWrapper">
      <div class="simulatorInputGroup">
        <div class="simulatorInputGroupSection">
          <img class="simulatorInputToggle" src="" [appSimulatorInputToggle]="getSensorStateDigital('sensor2')" (click)="toggleSensorState('sensor2')">
          <img class="simulatorInputButton" src="assets/image/simulator_button.svg" (mousedown)="setSensorState('sensor2',100)" (touchstart)="setSensorState('sensor2',100)" (mouseup)="setSensorState('sensor2',0)" (touchend)="setSensorState('sensor2',0)">
        </div>
        <div class="simulatorInputGroupSection">
          <!--duplicated change event as workaround for ie in angular2 rc.0-->
          <input class="simulatorInputAnalogRange" type="range" name="simulationSpeed" min="0" max="100" step="1" [(ngModel)]="sensorState.sensor2" (change)="setSensorState('sensor2',$event.target.value)">
        </div>
        <div class="simulatorInputGroupSection" style="justify-content: flex-end">
          <input class="simulatorInput" type="text" [(ngModel)]="sensorState.sensor2">
        </div>
      </div>
      <div class="simulatorInputGroupTitle"><p>Sensor 2</p></div>
    </div>

    <div class="simulatorInputGroupWrapper">
      <div class="simulatorInputGroup">
        <div class="simulatorInputGroupSection">
          <img class="simulatorInputToggle" src="" [appSimulatorInputToggle]="getSensorStateDigital('sensor3')" (click)="toggleSensorState('sensor3')">
          <img class="simulatorInputButton" src="assets/image/simulator_button.svg" (mousedown)="setSensorState('sensor3',100)" (touchstart)="setSensorState('sensor3',100)" (mouseup)="setSensorState('sensor3',0)" (touchend)="setSensorState('sensor3',0)">
        </div>
        <div class="simulatorInputGroupSection">
          <!--duplicated change event as workaround for ie in angular2 rc.0-->
          <input class="simulatorInputAnalogRange" type="range" name="simulationSpeed" min="0" max="100" step="1" [(ngModel)]="sensorState.sensor3" (change)="setSensorState('sensor3',$event.target.value)">
        </div>
        <div class="simulatorInputGroupSection" style="justify-content: flex-end">
          <input class="simulatorInput" type="text" [(ngModel)]="sensorState.sensor3">
        </div>
      </div>
      <div class="simulatorInputGroupTitle"><p>Sensor 3</p></div>
    </div>

    <div class="simulatorInputGroupWrapper">
      <div class="simulatorInputGroup">
        <div class="simulatorInputGroupSection">
          <img class="simulatorInputToggle" src="" [appSimulatorInputToggle]="getSensorStateDigital('sensor4')" (click)="toggleSensorState('sensor4')">
          <img class="simulatorInputButton" src="assets/image/simulator_button.svg" (mousedown)="setSensorState('sensor4',100)" (touchstart)="setSensorState('sensor4',100)" (mouseup)="setSensorState('sensor4',0)" (touchend)="setSensorState('sensor4',0)">
        </div>
        <div class="simulatorInputGroupSection">
          <!--duplicated change event as workaround for ie in angular2 rc.0-->
          <input class="simulatorInputAnalogRange" type="range" name="simulationSpeed" min="0" max="100" step="1" [(ngModel)]="sensorState.sensor4" (change)="setSensorState('sensor4',$event.target.value)">
        </div>
        <div class="simulatorInputGroupSection" style="justify-content: flex-end">
          <input class="simulatorInput" type="text" [(ngModel)]="sensorState.sensor4">
        </div>
      </div>
      <div class="simulatorInputGroupTitle"><p>Sensor 4</p></div>
    </div>
  </div>
</div>

<div #editorModalDivOutput id="editorModalSimulatorOutputWrapper" class="modalDialog">
  <div id="simulatorMotorOutputWrapper" class="simulatorOutputGroup">
    <div class="motorGroup">
      <p>M1</p>
      <input class="simulatorInput" [(ngModel)]="motorState.motor1" readonly>
      <div *ngIf="motorState.motor1<0" [style.opacity]="getMotorAsPercentageString('motor1')"><img src="assets/image/simulator_led_red.svg" /></div>
      <div *ngIf="motorState.motor1>=0" [style.opacity]="getMotorAsPercentageString('motor1')"><img src="assets/image/simulator_led_green.svg" /></div>

    </div>
    <div class="motorGroup">
      <p>M2</p>
      <input class="simulatorInput" [(ngModel)]="motorState.motor2" readonly>
      <div *ngIf="motorState.motor2<0" [style.opacity]="getMotorAsPercentageString('motor2')"><img src="assets/image/simulator_led_red.svg" /></div>
      <div *ngIf="motorState.motor2>=0" [style.opacity]="getMotorAsPercentageString('motor2')"><img src="assets/image/simulator_led_green.svg" /></div>
    </div>
  </div>
  <div id="simulatorLedOutputWrapper" class="simulatorOutputGroup">
    <div class="ledGroup">
      <p>L1</p>
      <input class="simulatorInput" [(ngModel)]="ledState.led1" readonly>
      <div [style.opacity]="getLedStateAsPercentageString('led1')"><img src="assets/image/simulator_led_red.svg" /></div>
    </div>
    <div class="ledGroup">
      <p>L2</p>
      <input class="simulatorInput" [(ngModel)]="ledState.led2" readonly>
      <div [style.opacity]="getLedStateAsPercentageString('led2')"><img src="assets/image/simulator_led_red.svg" /></div>
    </div>
    <div class="ledGroup">
      <p>L3</p>
      <input class="simulatorInput" [(ngModel)]="ledState.led3" readonly>
      <div [style.opacity]="getLedStateAsPercentageString('led3')"><img src="assets/image/simulator_led_red.svg" /></div>
    </div>
    <div class="ledGroup">
      <p>L4</p>
      <input class="simulatorInput" [(ngModel)]="ledState.led4" readonly>
      <div [style.opacity]="getLedStateAsPercentageString('led4')"><img src="assets/image/simulator_led_red.svg" /></div>
    </div>
  </div>
</div>

<div #editorModalDivSpeed id="editorModalDivSpeedWrapper" class="modalDialog">
  <p>Simulations-<br>geschwindigkeit</p>
  <!--duplicated change event as workaround for ie in angular2 rc.0-->
  <input id="simulationSpeedRange" type="range" name="simulationSpeed" min="10" max="500" step="10" [(ngModel)]="simulatorSpeed" (change)="simulatorSpeed=$event.target.value">
</div>
