import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output,
  ViewChild
} from '@angular/core';
import { ProgramService } from '../program.service';
import { ProgramAudioComponent } from '../program-audio/program-audio.component';
import { Program } from '../program';
import { ProgramSyncStatus } from '../program-local';

@Component({
  selector: 'app-editor-modal-connect',
  templateUrl: './editor-modal-connect.component.html',
  styleUrls: ['./editor-modal-connect.component.css']
})
export class EditorModalConnectComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input('mainContentWidth') contentWidth: string;
  @Input('mainContentHeight') contentHeight: string;

  @Output() onProgramNew: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onProgramLoad: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() teachersBoxPlugged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('editorModalDiv') editorModalDiv: ElementRef;
  @ViewChild('audioTeachersBoxUpload') audioTeachersBoxUpload: ElementRef;
  @ViewChild('audioTeachersBoxArm') audioTeachersBoxArm: ElementRef;
  @ViewChild('outline') outlineDiv: ElementRef;
  @ViewChild(ProgramAudioComponent) audioComponent: ProgramAudioComponent;

  selectedProgram: Program;
  private programService: ProgramService;
  programs: Array<Program>;
  errorMessage: string;
  private updatePollIntervalId;
  teachersBoxIsReady: boolean;
  teachersBoxIsBusy: boolean;

  currentProgramSyncStatus(): ProgramSyncStatus {
    return this.programService.getCurrentProgram().syncStatus;
  }

  get currentProgramName(): string {
    const currentProgram = this.programService.getCurrentProgram();
    return currentProgram.name !== null ? currentProgram.name : '';
  }

  set currentProgramName(newName: string) {
    this.programService.updateProgramName(newName);
  }

  constructor(programService: ProgramService) {
    this.programService = programService;
    this.selectedProgram = null;
    this.teachersBoxIsBusy = false;
    this.teachersBoxIsReady = false;
  }

  ngAfterViewInit() {
    this.updateModalBoxSize();
    this.pollPrograms();
    this.updatePollIntervalId = setInterval(() => { this.pollPrograms(); }, 3000);
  }

  ngOnChanges(changes: {}): any {
    this.updateModalBoxSize();
  }

  ngOnDestroy() {
    if (this.updatePollIntervalId) {
      clearInterval(this.updatePollIntervalId);
    }
    this.teachersBoxPlugged.emit(false);
  }

  onSyncClick() {
    if (this.programService.syncCurrentProgram(this.markTeachersBoxAsBusy.bind(this))) {
      const currentProg = this.programService.getCurrentProgram();
      if (currentProg.id != null) {
        for (const prog of this.programs) {
          if (prog.id === currentProg.id) {
            prog.markNotCompiled();
            break;
          }
        }
      }
      this.audioComponent.playUploadSound();
    }
  }

  onProgramToggle(program: Program) {
    if (this.selectedProgram != null && this.selectedProgram == program) {
      this.selectedProgram = null;
    } else {
      this.selectedProgram = program;
    }
  }

  onNewClick() {
    if (confirm('Aktuelles Programm überschreiben?')) {
      this.programService.reset();
      this.onProgramNew.emit(true);
    }
  }

  onSyncOnReturn(el) {
    this.onSyncClick();
    el.blur();
  }

  onNewSyncClick(programName: string) {
    if (programName !== '' && (this.programService.getCurrentProgram().compiled || this.programService.getCurrentProgram().hasCompileError)) {
      this.programService.updateProgramName(programName);
      this.programService.duplicateProgram(this.programService.getCurrentProgram(), true);
      // this.audioTeachersBoxUpload.nativeElement.play();
      this.audioComponent.playUploadSound();
      this.pollPrograms();
    }
  }

  onProgramSelection(program: Program) {
    this.selectedProgram = program;
  }

  onLoadSelectedProgram() {
    if (this.selectedProgram != null) {
      if (confirm('Aktuelles Programm überschreiben?')) {
        this.programService.setCurrentProgram(this.selectedProgram);
        this.programService.getCurrentProgram().syncStatus = ProgramSyncStatus.InSync;
        this.currentProgramName = this.selectedProgram.name;
        this.onProgramLoad.emit(true);
        this.close.emit(true);
        this.teachersBoxPlugged.emit(false);
      }
    }
  }

  onDeleteSelectedProgram() {
    if (this.selectedProgram != null) {
      if (confirm('Programm wirklich löschen?')) {
        this.programService.deleteProgram(this.selectedProgram, this.markTeachersBoxAsBusy.bind(this));
        this.selectedProgram = null;
        this.pollPrograms();
      }
    }
  }

  onArmSelectedProgram() {
    if (this.selectedProgram != null) {
      this.programService.armProgram(this.selectedProgram, this.markTeachersBoxAsBusy.bind(this));
      this.audioComponent.playArmSound();
    }
  }

  onDuplicateSelectedProgram() {
    if (this.selectedProgram != null) {
      this.programService.duplicateProgram(this.selectedProgram);
      this.pollPrograms();
    }
  }

  onOutlineClick() {
    this.close.emit(true);
    this.teachersBoxPlugged.emit(false);
  }

  getCurrentProgram() {
    return this.programService.getCurrentProgram();
  }

  localSectionCanConnect() {
    return this.currentProgramName !== '' && this.teachersBoxIsReady && !this.teachersBoxIsBusy;
  }

  hasArmedProgram() {
    let hasArmed = false;
    if (this.teachersBoxIsReady) {
      for (const prog of this.programs) {
        if (prog.armed) {
          hasArmed = true;
          break;
        }
      }
    }
    return hasArmed;
  }

  private pollPrograms() {
    this.programService.getPrograms(
      (programs: Array<Program>) => {
        this.updatePrograms(programs);
        this.teachersBoxIsReady = true;
        this.teachersBoxPlugged.emit(true);
        this.errorMessage = '';
      },
      () => {
        this.errorMessage = 'Keine Teacher’s Box gefunden.';
        this.programs = [];
        this.teachersBoxIsReady = false;
        this.teachersBoxPlugged.emit(false);
      });
  }

  private updatePrograms(programs: Array<Program>) {
    this.programs = programs;
    if (programs.length === 0) {
      this.selectedProgram = null;
    }
    if (this.selectedProgram != null) {
      programs.forEach((prog: Program) => {
        if (this.selectedProgram.id == prog.id) {
          this.selectedProgram = prog;
          return;
        }
      });
    }
  }

  private updateModalBoxSize() {
    if (this.editorModalDiv) {
      const topNavigationHeight = 50;
      const verticalSpacing = 20;
      const adjustedHeight = parseInt(this.contentHeight) - 2 * verticalSpacing - 20; //for some reason, height is 20 px less on iPad
      this.editorModalDiv.nativeElement.style.top = topNavigationHeight + verticalSpacing;
      this.editorModalDiv.nativeElement.style.height = adjustedHeight;

      const leftNavigationWidth = 80;
      const horizontalSpacing = 45;
      const adjustedWidth = parseInt(this.contentWidth) - leftNavigationWidth - 2 * horizontalSpacing;
      this.editorModalDiv.nativeElement.style.left = leftNavigationWidth + horizontalSpacing;
      this.editorModalDiv.nativeElement.style.width = adjustedWidth;
    }
    if (this.outlineDiv) {
      this.outlineDiv.nativeElement.style.width = this.contentWidth;
      // this.outlineDiv.nativeElement.style.height = this.contentHeight;
    }

  }

  private markTeachersBoxAsBusy() {
    this.teachersBoxIsBusy = true;
    setTimeout(() => { this.teachersBoxIsBusy = false; }, 1000);
  }

}
