import {Program} from './program';
export enum ProgramSyncStatus {
  NotListed = 0,
  InSync = 1 ,
  OutOfSync = 2
}

export class ProgramLocal extends Program{
  constructor(
    public id: number,
    public name: string,
    public sourceCode: string,
    public blocklyXml: string,
    public createdOn: Date,
    public modifiedOn: Date,
    public compiled: boolean = false,
    public armed: boolean = false,
    public hasCompileError: boolean = false,
    public syncStatus: ProgramSyncStatus = ProgramSyncStatus.NotListed
  ) {
    super(id, name, sourceCode, blocklyXml, createdOn, modifiedOn);
  }

  static createEmpty(): ProgramLocal {
    return new ProgramLocal(null, null, null, null, null, null);
  }

  static createWithXML(xml: string): ProgramLocal {
    return new ProgramLocal(null, null, null, xml, null, null);
  }

  static createWithProgram(prog: Program): ProgramLocal {
    return new ProgramLocal(prog.id, prog.name, prog.sourceCode, prog.blocklyXml, prog.createdOn, prog.modifiedOn,
      prog.compiled, prog.armed, prog.hasCompileError);
  }

  static loadFromLocalStorage(): ProgramLocal {
    if (typeof (Storage) !== 'undefined') {
      const id = localStorage.getItem('pgluCurrentProg_id');
      const idNumber = (id === null || id === 'null') ? null : parseInt(id);
      let name = localStorage.getItem('pgluCurrentProg_name');
      name = (name === null || name === 'null') ? null : name;
      let sourceCode = localStorage.getItem('pgluCurrentProg_sourceCode');
      sourceCode = (sourceCode === null || sourceCode === 'null' ) ? null : sourceCode;
      let blocklyXml = localStorage.getItem('pgluCurrentProg_blocklyXml');
      blocklyXml = (blocklyXml === null || blocklyXml === 'null') ? null : blocklyXml;
      const createdOn = localStorage.getItem('pgluCurrentProg_createdOn');
      const createdOnDate = (createdOn === null || createdOn === 'null' || createdOn === undefined) ? null : new Date(createdOn);
      const modifiedOn = localStorage.getItem('pgluCurrentProg_modifiedOn');
      const modifiedOnDate = (modifiedOn === null || modifiedOn === 'null' || modifiedOn === undefined) ? null : new Date(modifiedOn);
      return new ProgramLocal(idNumber, name, sourceCode, blocklyXml, createdOnDate, modifiedOnDate);
    }else {
      return this.createEmpty();
    }
  }

  static getJSONReplacer(): any {
    return (key, value) => {
      if (key === 'syncStatus' || key === 'compiled' || key === 'armed' || key === 'hasCompileError') {
        return undefined;
      }else {
        return value;
      }
    };
  }

  updateStatusInformationWithServerResponse(prog: Program) {
    this.syncStatus = (this.sourceCode === prog.sourceCode && this.name === prog.name) ?
      ProgramSyncStatus.InSync : ProgramSyncStatus.OutOfSync;
    this.armed = prog.armed;
    this.compiled = prog.compiled;
    this.hasCompileError = prog.hasCompileError;
  }

  markNotListed() {
    this.syncStatus = ProgramSyncStatus.NotListed;
    this.armed = false;
    this.compiled = false;
    this.hasCompileError = false;
  }

  saveToLocalStorage() {
    if (typeof (Storage) !== 'undefined') {
      localStorage.setItem('pgluCurrentProg_id', (this.id != null && !isNaN(this.id)) ? this.id.toString() : null);
      localStorage.setItem('pgluCurrentProg_name', this.name != null ? this.name : null);
      localStorage.setItem('pgluCurrentProg_sourceCode', this.sourceCode);
      localStorage.setItem('pgluCurrentProg_blocklyXml', this.blocklyXml);
      localStorage.setItem('pgluCurrentProg_createdOn', this.createdOn != null ? this.createdOn.toString() : null);
      localStorage.setItem('pgluCurrentProg_modifiedOn', this.modifiedOn != null ? this.modifiedOn.toString() : null);
    }
  }
}
