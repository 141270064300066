<div #outline class="modalOutline" (click)="onOutlineClick()"></div>
<div #editorModalDiv id="editorModalConnectWrapper" class="modalDialog">
  <div id="modalConnectNavTriangleWrapper"><div class="navTriangle"></div></div>
  <div class="connectLocalSection">
    <ul class="navSmall">
      <li class="title">Mein Computer</li>
      <li (click)="onSyncClick()" [class.inactive]="!localSectionCanConnect()">In Teacher’s Box sichern</li>
      <li (click)="onNewSyncClick(programName.value)" [class.inactive]="!localSectionCanConnect() || getCurrentProgram().syncStatus == 0">Als Kopie in Teacher’s Box sichern</li>
      <li (click)="onNewClick()">Neues Programm</li>
    </ul>
    <div class="localInputWrapper">
      <div class="inputWrapper">
        <div class="programStatusCircleWrapper"><div class="programStatusCircles programStatusCirclesLocal"><img *ngIf="teachersBoxIsReady && getCurrentProgram().syncStatus == 1" class="statusCircle statusCircleLocal" src="/assets/image/dot_blue_synced.svg" /><img *ngIf="teachersBoxIsReady && getCurrentProgram().syncStatus == 2" class="statusCircle statusCircleLocal" src="/assets/image/dot_blue_outofsync.svg" /></div></div>
        <input #programName [(ngModel)]="currentProgramName" (keyup.enter)="onSyncOnReturn(programName)" class="inputBlack" placeholder="Programmname eingeben" max="255">
      </div>
    </div>

  </div>
  <div class="connectServerSection">
    <div class="programStatusCircleWrapper" *ngIf="hasArmedProgram()"><div class="programStatusCircles"><img class="statusCircle statusCircleArmed" src="/assets/image/dot_green.svg" /></div></div>
    <ul class="navSmall">
      <li class="title" [class.error]="teachersBoxIsBusy" [class.inactive]="!teachersBoxIsReady">Teacher’s Box</li>
      <li [class.inactive]="selectedProgram === null" (click)="onLoadSelectedProgram()">In meinen Computer laden</li>
      <li [class.inactive]="selectedProgram === null" (click)="onDuplicateSelectedProgram()">Duplizieren</li>
      <li [class.inactive]="selectedProgram === null" (click)="onDeleteSelectedProgram()">Löschen</li>
      <li [class.inactive]="selectedProgram === null || !selectedProgram.compiled" (click)="onArmSelectedProgram()" style="float: right; padding-right: 40px">An Platine senden</li>
    </ul>
  </div>
  <div class="serverResponseWrapper">
    <p class="error" *ngIf="errorMessage">{{errorMessage}}</p>
    <div *ngFor="let program of programs" class="program" [class.compiling]="!program.compiled && !program.hasCompileError" (click)="onProgramToggle(program)">
      <ul class="programAttributes" [class.selected]="selectedProgram && program.id === selectedProgram.id" [class.error]="program.hasCompileError">
        <li class="date">{{program.modifiedOn | date:"y-MM-dd"}}</li>
        <li class="time">{{program.modifiedOn | date:"HH:mm"}}</li>
        <li class="name">{{program.name}}</li>
        <div class="programStatusCircleWrapper"><div class="programStatusCircles"><img *ngIf="program.armed" class="statusCircle statusCircleArmed" src="/assets/image/dot_green.svg" /><img class="statusCircle statusCircleLocal" *ngIf="program.id === getCurrentProgram().id && getCurrentProgram().syncStatus == 1" src="/assets/image/dot_blue_synced.svg" /><img class="statusCircle statusCircleLocal" *ngIf="program.id === getCurrentProgram().id && getCurrentProgram().syncStatus == 2" src="/assets/image/dot_blue_outofsync.svg" /></div></div>
      </ul>
    </div>
  </div>
</div>
<app-program-audio></app-program-audio>
