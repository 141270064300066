import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild

} from '@angular/core';
import { SettingsService } from '../settings-service';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html'
})
export class SettingModalComponent implements AfterViewInit, OnChanges {

  @Input('mainContentWidth') contentWidth: string;
  @Input('mainContentHeight') contentHeight: string;
  @ViewChild('editorModalDiv') editorModalDiv: ElementRef;
  @ViewChild('outline') outlineDiv: ElementRef;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  public oldboard = true;
  constructor(private settingsService: SettingsService) {

  }

  ngAfterViewInit() {
    this.updateModalBoxSize();
    this.settingsService.getSettings().subscribe((res) => {
      this.oldboard = (res !== 2);
    })
  }

  ngOnChanges(changes: {}): any {
    this.updateModalBoxSize();
  }

  callService() {
    
    this.oldboard = !this.oldboard;
   
    this.settingsService.updateSettings(this.oldboard ? 1 : 2).subscribe(() => {
      console.log('called successfuly');
      
    })
  }

  onOutlineClick() {
    this.close.emit(true);
  }

  private updateModalBoxSize() {
    if (this.editorModalDiv) {
      const topNavigationHeight = 50;
      const verticalSpacing = 20;
      const adjustedHeight = parseInt(this.contentHeight) - 2 * verticalSpacing - 20; // for some reason, height is 20 px less on iPad
      this.editorModalDiv.nativeElement.style.top = topNavigationHeight + verticalSpacing;
      this.editorModalDiv.nativeElement.style.height = adjustedHeight;

      const leftNavigationWidth = 80;
      const horizontalSpacing = 45;
      const adjustedWidth = parseInt(this.contentWidth) - leftNavigationWidth - 2 * horizontalSpacing;
      this.editorModalDiv.nativeElement.style.left = leftNavigationWidth + horizontalSpacing;
      this.editorModalDiv.nativeElement.style.width = adjustedWidth;
    }
    if (this.outlineDiv) {
      this.outlineDiv.nativeElement.style.width = this.contentWidth;      
    }

  }




}
