import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Program} from './program';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ProgramLocal} from './program-local';

@Injectable()
export class ProgramBackendService {

  // private static urlBase = "http://localhost:9000";
  // private static urlBase = "http://192.168.10.1:9000";
  private  urlBase = "http://192.168.10.1:9000";
  // private static urlBase = "http://152.96.214.138:9000";

  private static jsonHeaderOption = {headers: new HttpHeaders({'Content-Type':  'application/json'})};

  constructor(private _http: HttpClient) {
    this.urlBase = location.origin + ':9000';
  }

  createOrUpdateProgram(program: Program): Observable<Program> {
    if (program.id == null) {
      return this._http.post(this.urlBase + '/program', JSON.stringify(program, ProgramLocal.getJSONReplacer()),
        ProgramBackendService.jsonHeaderOption).pipe(map(prog => this.createProgramFromJSON(prog)));
    }else {
      return this._http.put(this.urlBase + '/program', JSON.stringify(program, ProgramLocal.getJSONReplacer()),
        ProgramBackendService.jsonHeaderOption).pipe(map(prog => this.createProgramFromJSON(prog)));
    }
  }

  armProgram(program: Program) {
    return this._http.get(this.urlBase + '/program/arm/' + program.id);
  }

  deleteProgram(program: Program) {
    return this._http.delete(this.urlBase + '/program/' + program.id);
  }

  getPrograms(): Observable<Program[]> {
    return this._http.get(this.urlBase + '/program')
    .pipe(map((programs: Array<any>) => {
          const result: Array<Program> = [];
          if (programs) {
            for (const program of programs) {
              result.push(this.createProgramFromJSON(program));
            }
          }
          return result;
        }
      ));
  }

  private createProgramFromJSON(jsonProg): Program {
    return new Program(
      jsonProg.id,
      jsonProg.name,
      jsonProg.sourceCode,
      jsonProg.blocklyXml,
      new Date(jsonProg.createdOn as string),
      new Date(jsonProg.modifiedOn as string),
      jsonProg.compiled,
      jsonProg.armed,
      jsonProg.hasCompileError
    );
  }

}
