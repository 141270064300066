import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appSimulatorInputToggle]'
})
export class SimulatorInputToggleDirective {

  @Input() set appSimulatorInputToggle(activated: boolean) {
    if (activated) {
      this.el.setAttribute('src', 'assets/image/simulator_toggle_on.svg');
    }else {
      this.el.setAttribute('src', 'assets/image/simulator_toggle_off.svg');
    }

  }

  private el: HTMLElement;
  constructor(el: ElementRef) { this.el = el.nativeElement; }

}
